import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button, { VARIANT } from '@components/Button/Button.js';
import style from './ThankyouPage.module.scss';
import { trackBookCallPageVisit } from '../../kissmetricsEvents';
import { globalHistory as history } from '@reach/router';

const ThankyouPage = ({ content }) => {
  const { title, pagemessage, buttontext, buttonlink } = content;
  const { location } = history;

  useEffect(() => {
    trackBookCallPageVisit(location.pathname);

    const scriptContent = `!function(e,t,n,o,p,i,a){e[o]||((p=e[o]=function(){p.process?p.process.apply(p,arguments):p.queue.push(arguments)}).queue=[],p.t=+new Date,(i=t.createElement(n)).async=1,i.src="https://pvdpix.com/pixel.js?t="+864e5*Math.ceil(new Date/864e5),(a=t.getElementsByTagName(n)[0]).parentNode.insertBefore(i,a))}(window,document,"script","pvd"),pvd("init","164a37c9-15d5"),pvd("event","pageload");`;

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = scriptContent;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location.pathname]);

  return (
    <div className={style.thankyouPage}>
      <div className={style.container}>
        <div className={style.thankyouBoard}>
          <h1 className={style.title}>{title.text}</h1>
          <p className={style.pageMessage}>{pagemessage.text}</p>
          <div className={style.thankyouButtonWrapper}>
            <Button to={buttonlink.text} variant={VARIANT.THANKYOU}>
              {buttontext.text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ThankyouPage.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ThankyouPage;
